import {
	redirect,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { type ExternalScriptsHandle } from 'remix-utils/external-scripts'
import { getUserId } from '#app/utils/auth.server.ts'
import { useTheme } from '../resources+/theme-switch.tsx'

export let handle: ExternalScriptsHandle = {
	scripts: [
		{
			src: '/js/scw-marketing.js',
			type: 'module',
			async: true,
			crossOrigin: 'anonymous',
			preload: true, // use it to render a <link rel="preload"> for this script
		},
	],
}

// export const links: LinksFunction = () => {
// 	return [
// 		{ rel: 'stylesheet', href: chatBoxStyleSheetUrl },
// 		{ rel: 'stylesheet', href: chatBoxCustomeStyleSheetUrl },
// 	]
// }

export const meta: MetaFunction = () => [{ title: 'Supervity AI' }]

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const userId = await getUserId(request)
	if (userId) {
		return redirect('/u/dashboard')
	}
	return null
}

export default function Index() {
	const theme = useTheme()

	return (
		<main className="font-poppins grid h-full place-items-center">
			<div className="grid place-items-center px-4 py-16 xl:grid-cols-2 xl:gap-24">
				<div className="flex max-w-md flex-col items-center text-center xl:order-2 xl:items-start xl:text-left">
					<a
						href="https://www.supervity.ai"
						className="animate-slide-top [animation-fill-mode:backwards] xl:animate-slide-left xl:[animation-delay:0.5s] xl:[animation-fill-mode:backwards]"
					>
						<img
							className="size-20 text-foreground xl:-mt-4"
							src={
								theme === 'dark'
									? '/img/supervity-logo-dark.png'
									: '/img/supervity-logo.svg'
							}
							alt="Supervity Logo AI Agent"
						/>
					</a>
					<h1
						data-heading
						className="mt-8 animate-slide-top text-3xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] dark:text-slate-400 md:text-4xl xl:mt-4 xl:animate-slide-left xl:text-5xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						<a href="https://www.supervity.ai">Supervity AI Coach</a>
					</h1>
					<p
						data-paragraph
						className="mt-6 animate-slide-top text-xl/7 text-primary [animation-delay:0.8s] [animation-fill-mode:backwards] xl:mt-8 xl:animate-slide-left xl:text-xl/6 xl:leading-10 xl:[animation-delay:1s] xl:[animation-fill-mode:backwards]"
					>
						Empower your workflow with a customizable AI companion!
					</p>
				</div>
				<div className="shadow-soft-xl dark:shadow-soft-xl-dark min-h-[400px] w-full animate-slide-top overflow-hidden rounded-xl bg-background [animation-fill-mode:backwards] xl:animate-slide-left xl:[animation-delay:0.5s] xl:[animation-fill-mode:backwards]">
					<div id="scw-marketing-demo"></div>
				</div>
			</div>
		</main>
	)
}
